/** @jsx jsx */
import { jsx } from '@emotion/react'

import { success } from '../assets/styles/AboutTwo.styles'

import ImgIco from "../assets/images/LP-kingdom/404.svg";

const SuccessContent = () => {
  return (
    <section css={success}>
      <div>
        <img src={ImgIco} alt="imagem" />
        <h1>Ooops, algo deu errado</h1>
        <p>Fique tranquilo, nossos técnicos estão trabalhando nisso.</p>
      </div>
    </section>
  )
}

export default SuccessContent
